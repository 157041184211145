.CTA {
    padding-bottom: 3%;
}
.CTA-body-mission {
    padding: 3% 0;
}

.rocket {
    width: 4%;
    padding-bottom: 3%
}

.homepage-paragraph {
    padding-bottom: 3%;
}

.our-team-body-container {
    margin-left: 7%;
    padding-top: 7%;
}

.headshot-container {
    display: flex;
    padding: 2% 0;
    width: 90%;
}

.headshot {
    display: block;
    margin: 0;
    cursor: pointer;
}

.headshot-header {
    font-family: Proza Libre Bold;
    margin-top: 10%;
    margin-bottom: 5%;
}

.headshot-subheader {
    font-family: Roboto Regular;
    color: #F0BB46;
}

.bio-content {
    width: 75%;
    margin: 0 14%;
}

.exit-bio {
    cursor: pointer;
    position:absolute;
    right: 14%;
    width: 2%;
}

@media screen and (max-width: 500px), (max-height: 600px) {
    .CTA-mission {
        padding-top: 18%;

    }
    .CTA-body-mission {
        width: 80%;
    }

    .rocket {
        margin-top: 15%;
        width: 19%;
    }

    .homepage-paragraph-mission {
        width: 80%;
    }

    .homepage-body-container-mission {
        padding-bottom: 20%;
    }

    .headshot-container {
        display: block;
        margin-bottom: 20%;
    }

    .headshot {
        margin-top: 20%;
    }

    .headshot-header {
        font-size: 24px;
    }

    .headshot-subheader {
        font-weight: bold;
    }

    .bio-content {
        margin: 5% 0px;
        width: 100%;
    }

    .exit-bio {
        width: 7%;
    }
}