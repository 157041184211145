.CTA-body-contact-us {
    width: fit-content;
    margin-bottom: 18%;
    margin-top: 5%;
}

.CTA-contact-us {
    padding-top: 8%;
}

.contact-us-img {
    width: 20%;
    display: block;
    margin-bottom: 10%;
}

.homepage-subheader {
    text-align: left;
}

.email {
    text-align: left;
    margin-top: 2%;
    font-weight: bold;
}

.homepage-body-contact-us {
    padding-bottom: 5%;
}

a {
    color: white;
}

@media screen and (max-width: 500px), (max-height: 600px) {

    .homepage-header-contact-us {
        margin-top: 25%;
    }
}
