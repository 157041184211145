.nav-bar {
    z-index: 9999;
    /* position: fixed; */
    width: 100%;
    top:0;
    /* background-color: #2B2B43; */
    /* display: block; */
    display: flex;
}
.nav-container {
    margin: auto;
    width: 80%;
    height: auto;
    text-align: right;
    font-family: Roboto Regular;
    font-weight: bold;
    /* position: absolute; */
    /* left: 10%;
    top: 25%; */
}
.nav-link {
    color: #2B2B43;
    display:inline-flex;
    width: 12%;
}

.nav-bar-item {
    color: white;
    width: fit-content;
    display: inline-flex;
}

.contact-us {
    padding-right: 2%;
}

.login {
    width: 5%;
}

.logo {
    width: 90%;
    padding-left: 50%;
    padding-top: 20%;
    height: 100%;
}

span.title {
    color: white;
    font-weight: 700;
    font-size: 2vh;
    position: fixed;
    padding: 3px 10px;
}
.e {
    vertical-align: top;
}
.h {
    vertical-align: bottom;
}
.selected {
    color: #F0BB46
}

@media screen and (max-width: 500px), (max-height: 600px) {
    .nav-container {
        width: 100%;
        margin: 5%;
    }
    .nav-link {
        width: 40%;
    }
    .logo {
        width: 100%;
        margin: 12% 5%;
        padding: unset;
    }
    .hamburger {
        background-color: #161626;
        position: fixed;
        display: block;
        z-index: 2;
        border: 1px solid #F0BB46;
        border-radius: 4px;
        margin: 5%;
    }

    .hamburger .nav-link {
        width: 100%;
        padding: 5%;
    }

    .hamburger .MuiButtonBase-root {
        justify-content: unset;
    }

    .orange-x {
        width: 8%;
        margin-top: 5%;
        margin-right: 5%;
        margin-bottom: 5%;
        position: absolute;
        right: 0;
    }
    

}