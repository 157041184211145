p {
    color: white;
    margin: auto;
    font-size: 18px;
}
h1 {
    color: white;
}
a {
    color:#4de1ff;
}

.home-container {
    height: 80%;
    width: 80%;
    position: relative;
    margin: 2% auto;
}

.CTA {
    /* background-color: #2B2B43; */
    text-align: center;
    border-radius: 8px;
    padding: 2%;
    margin: auto;
    display: flex;
    height: 40vh;
    position: relative;
    padding-bottom: 5%;
}
.CTA-left {
    align-self: end;
    margin-left: 5%;
}
.CTA-right {
    display: inline;
    width: 75%;
}
.CTA-header {
    font-family: Proza Libre Bold;
    color: #F0BB46;
    font-size: 50px;
    text-align: left;
    line-height: 100%;
    white-space: nowrap;
}
.CTA-body {
    font-family: Roboto Regular;
    text-align: left;
    padding: 5% 0px;
    width: 70%;
    margin-left: 0px;
    font-size: 20px;
}
.CTA-button {
    float: left;
    width: 40%;
    cursor: pointer;
}
.CTA-image {
    width: 100%;
    height: 100%;
}

.homepage-body {
    background-color: #0B3D84;
}

.homepage-body-container {
    width: 93%;
    margin: 0 7%;
    padding-top: 5%;
}
.homepage-header {
    font-family: Proza Libre Bold;
    font-size: 35px;
}

.homepage-subheader {
    font-family: Roboto Regular;
    color: #F0BB46;
    font-weight: bold;
    font-size: 20px;
}
.homepage-paragraph {
    font-family: Roboto Regular;
    padding-bottom: 5%;
    margin-left: 0px;
    width:60%;
}

.up-arrow {
    width: 6%;
}

.double-dash {
    float: right;
    padding-right: 5%;
    width: 5%;
}

.create-account {
    background-color: #4de1ff;
    border-radius: 8px;
    padding: 2%;
    color: #14141f;
    font-size: 32px;
    font-weight: 900;
    margin: auto;
    margin-top: 1%;
    width: 25%;
    cursor: pointer;
    display: inline-block;
}

div.title {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 32px;
}

.footer {
    color: white;
    font-size: 8px;
    width: 93%;
    position: relative;
    bottom: 0;
    margin: auto;
    display: flex;
    background-color: #0B3D84;
    padding-left: 7%;
}

.chevron {
    position: absolute;
    font-size: 2.5rem !important;
    cursor: pointer;
}

.headshot {
    display: flex;
    margin: auto;
    border-radius: 50%;
}

@media screen and (max-width: 500px), (max-height: 600px) {
    p {
        font-size: 14px;
    }
    .home-container {
        margin: 15% auto;
    }
    div.title {
        font-size: 28px;
    }

    .create-account {
        width: 50%;
        margin-top: 4%;
    }
    
    .CTA {
        padding: 5%;
        flex-direction: column;
        height: unset;
    }
    .CTA-left {
        order: 2;
        width: 100%;
        height: 100%;
        align-self: unset;
    }
    .CTA-right {
        order: 1;
        width: 88%;
    }
    .CTA-image {
        width: 100%;
    }
    .CTA-header {
        white-space: unset;
        font-size: 35px;
    }
    .CTA-body {
        width: 90%;
        font-size: 16px;
    }
    .CTA-button {
        width: 60%;
    }
    .double-dash {
        width: 14%;
    }
    .up-arrow {
        margin-top: 15%;
        width: 19%;
    }
    .homepage-paragraph {
        width: 95%;
    }
    .homepage-subheader {
        font-size: 16px;
    }

}