a {
    color: #D9D9D9;
    cursor: pointer;
    align-self: center;
}

li {
    display: flex;
    margin: 2% 1%;
    padding: 2%;
    background-color: #3b3b63;
    border-radius: 4px;
}

li a {
    color:#74aadc;
    font-weight: 900;
}

.filter-label {
    background-color: #F0BB46;
    width:70%;
    font-weight: 500;
    padding: 1%;
    border-radius: 4px;
}
.filter-label-text {
    color: #161626;
    display: flex;
    align-items: center;
    font-family: Roboto Regular;
    width: 95%;
    justify-content: space-between;
}
p.percent{
    margin-right:3%;
}
.highest-move-container {
    display: flex;
    width: 100%;
    z-index: 2;
    background-color: #3b3b63;
    top: 43%;
    border-radius: 4px;
    z-index: 9999;

}
.highest-move-list {
    /* border: 1px solid white; */
    /* width: 95%; */
    /* height: 100%; */
    overflow: auto; 
    height: 63%;   
    display: grid;
    grid-template-columns: 50% 50%;
    max-height: unset;
    padding: 0;
    margin-top: 2%;
    grid-auto-rows: 10%;
}
.tab-container {
    width: 100%;
}
.feature-tab {
    height: 6vh;
    width: 3vw;
    /* border: 1px solid white; */
    color: white;
    text-align: center;
    line-height: 6vh;
    cursor: pointer;
}

.feature-tab svg.MuiSvgIcon-root {
    vertical-align: middle;
}
.feature-tab-container {
    display: flex;
    padding: 4% 4%;
}
.feature-tab-text {
    width: 70%;
    font-family: Roboto Regular;
    font-weight: 900;
    cursor: pointer;
}
.selected {
    color: #F0BB46;
}

.remove-ticker {
    margin-right: 7%;
    font-size: 24px;
    cursor: pointer;
}

.add-ticker {
    color: white;
    font-size: 24px;
    padding:2%;
    cursor: pointer;
}
svg.MuiSvgIcon-root.tooltip {
    margin: auto;
    margin-left: -10%;
    cursor: pointer;
}
@media screen and (max-width: 500px), (max-height: 600px) {
    .highest-move-container {
        display: flex;
        width: 20%;
        z-index: 2;
        background-color: #3b3b63;
        left: 5%;
        top: 29%;
        width: 70%;
        border-radius: 4px;
        z-index: 9999;
        position: absolute;
    }
    .tab-container {
        width: auto;
    }
    .feature-tab {
        width: 12.5vw;
        text-align: center;
    }
    .feature-tab-container {
        display: flex;
        padding: 8% 4%;
    }
    .highest-move-list {
        padding: 4%;
        max-height: 30%;
        height: fit-content; 
        grid-auto-rows: 5vh;
    }
    .filter-label {
        margin: 0 4%;  
    }
}