/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #222236;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #363654;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .earnings-hedge-container {
    height: 100vh;
    width:100vw;
    background-color:#161626;
    position: absolute;
    overflow: auto;
    padding: 0;
    margin: 0;
  }

@media screen and (max-width: 500px), (max-height: 600px) {
    .orientation-container {
        display: flex;
        flex-flow: column;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -khtml-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
}