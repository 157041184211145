.page-container {
    display: flex;
    height: 74vh;
    width: 90vw;
    margin: auto;
    margin-top: 2%;
    justify-content: space-between;
}
.content-row {
    display: flex;
    height: 100%;
    width: 75%;
    flex-direction: column;

}
.chart-container {
    width: auto;
    display: inline-block;
    background-color: #2B2B43;
    height: 100%;
    border-radius: 4px;
}
.criteria-bar {
    width: 24%;
    height: 100%;
    overflow: hidden;
    /* margin-left: 6%; */
    /* margin-right: 10%; */
}
.tv-lightweight-charts {
    margin: auto;
    min-width: 100% !important;
    max-width: 100% !important;
    /* border: 1px solid white; */
}
.MuiAutocomplete-root {
    height: 10% !important;
    width: 85% !important;
    margin: 1% !important;
}
.MuiAutocomplete-popper {
    background-color: #14141f !important;
    color: white;
}

.MuiAutocomplete-listbox {
    background-color: #3b3b63 !important;
    color: white;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.search-button {
    vertical-align: middle;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.time-frame-button {
    margin-top: 3%;
}
.MuiFormControl-root.MuiTextField-root.time-frame-button {
    margin-top: 5%;
}
.MuiList-root.MuiMenu-list.MuiList-padding {
    display: grid;
    background-color:#2B2B43;
    color: white;
}
.MuiInput-underline:before {
    border-bottom: unset !important;
}

.time-frame-button {
    color: #D9D9D9 !important;
    display: inline-flex;
}
.search-button {
    color: #D9D9D9 !important;
    cursor: pointer;
    margin-left: 2%;
}
.search-container {
    background-color: #2B2B43;
    border-radius: 4px;
    padding: 1% 0;
}
/* .search-button:hover {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
} */

.centered-content {
    width: 100vw;
    height: 100vh;
    text-align: center;
    vertical-align: middle;
    line-height: 100vh;
}
.no-data {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 75vh;
}
.chart-title {
    color: white;
}

.selected {
    color: #F0BB46;
}
svg.MuiSvgIcon-root.MuiSelect-icon {
    color: white;
}

.adjust-filter {
    width: fit-content;
    float: left;
    color: white;
    background-color: #3b3b63;
    font-family: Roboto Regular;
    font-weight: 900;
    display: flex;
    margin: 4% 0;
    padding: 3%;
    border-radius: 4px;
    cursor: pointer;
}

.criteria-content-sub-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.chart-title {
    background-color: #2B2B43;
    display: flex;
    justify-content: center;
}
.chart-title-highlighted {
    color: #F0BB46 !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.chart-title-text {
    color: white;
    font-weight: 900;
    margin: 0px;
    background-color: #3b3b63;
    padding: 1% 1%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: fit-content;
}

.filter-menu {
    /* height: 80vh;
    width: 80vw;
    position: fixed;
    z-index: 9999; */
}
.time-frame-button-container {
    display: flex;
    height: 100%;
    margin: auto 0;
    padding: 3%;
}

@media screen and (max-width: 500px), (max-height: 600px) {
    div.tv-lightweight-charts {
        /* width: 90% !important;
        margin-left: 40px; */
        /* margin-left: 1.5vw; */
    }

    div.tv-lightweight-charts table tr td:nth-child(3) {
        /* width: 23vw; */
    }
    div.tv-lightweight-charts table tr td:nth-child(3) div {
        /* width: 69% !important; */
        min-width: unset !important;
    }
    .chart-container {
        order: 2;
        width: 97%;
        height: 100%;
        margin-bottom: 0%;
        margin-left: 2vw;
    }
    .criteria-bar {
        width: 97%;
        margin: auto;
        background-color: #161626;
        border-radius: 4px;
        height: unset;
        overflow: unset;
    }
    .nav-bar {
        order: 1;
    }
    /* div.highest-move-container {
        order: 3;
        position: relative;
        margin: auto;
        width: 75%;
        height: 35vh;
        margin: 2% auto; 
    } */
    .content-row {
        display: block;
        height: fit-content;
        width: 100%;
    }
    .page-container {
        display: block;
        width: 97vw;
        justify-content: unset;
        height: 80vh;
        margin-top: 3%;

    }
    .MuiAutocomplete-root {
        width: 85% !important;
    }
    .highest-move-list {
        height: 92%;
    }
    .loading-gif {
        height: 14%;
        width: 25%;
        margin-top: -7%;
        margin-left: -12%;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.time-frame-button {
        min-width: unset;
    }
    .MuiInputBase-root {
        padding-right: 5px !important;
    }
    .no-data {
        line-height: 60vh;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
        font-family: Roboto Regular;
        font-weight: 900 !important;
    }
    .search-button {
        width: 13% !important;
        margin-left: unset;
        height: 100% !important; 
        vertical-align: middle !important;
        -webkit-align-self: center;
    }
    .time-frame-button-container {
        width: fit-content;
        background-color: #3b3b63;
        margin: 4% 0;
        border-radius: 4px;
        float: right;
        display: block;
        height: unset;
    }
    .chart-title {
        width: 97%;
        margin-left: 2vw;
        padding: 2% 0;
    }

    .criteria-content-sub-row {
        display: flex;
        justify-content: space-between;
        margin: auto;
    }
    .time-frame-button-container {
        display: flex;
        height: 100%;
    }
    .search-container {
        padding: unset;
    }
}